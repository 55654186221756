*, ::after, ::before {
    box-sizing: border-box !important;
}

@font-face {
    font-family: SegoeUI;
    src: url("../assets/fonts/segoe_ui_light.woff2") format("woff2");
    font-weight: 100;
}

@font-face {
    font-family: SegoeUI;
    src: url("../assets/fonts/segoe_ui_semilight.woff2") format("woff2");
    font-weight: 200;
}

@font-face {
    font-family: SegoeUI;
    src: url("../assets/fonts/segoe_ui_regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: SegoeUI;
    src: url("../assets/fonts/segoe_ui_semibold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-family: SegoeUI;
    src: url("../assets/fonts/segoe_ui_bold.woff2") format("woff2");
    font-weight: 700;
}

html {
    ::-webkit-scrollbar {
        display: none;
    }
}

body {
    font-family: SegoeUI, sans-serif;
    background-color: #090909;
    color: #fff;
    font-size: 16px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}


p {
    color: $text-color;
}

.contacts {
    padding: 0 25px;
    @media (min-width: 1280px) {
        padding: 0 120px 0 260px;
    }

    .content-wrapper {
        position: relative;
        z-index: 2;
        padding: 60px 0;

        @media (min-width: 768px) {
            padding: 25px;
        }
        @media (min-width: 1280px) {
            margin-top: 60px;
            padding: 30px 50px;
            background-color: #000;
        }

        p {
            margin-bottom: 10px;
            font-size: 16px;
            color: #858585;
            @media (min-width: 1280px) {
            }
            @media (min-width: 1440px) {
                //font-size: 20px;
            }
        }

        h3 {
            font-size: 24px;
            margin-bottom: 15px;
            @media (min-width: 1280px) {
                font-size: 24px;
            }
            @media (min-width: 1440px) {
                font-size: 28px;
            }
            @media (min-width: 1920px) {
                margin-bottom: 20px;
                font-size: 32px;
            }
        }
    }

    .socials {
        margin-top: 40px;
        margin-bottom: 30px;
        @media (min-width: 1920px) {
            margin-top: 70px;
        }

        a {
            margin-right: 25px;
            transition: filter 0.2s ease-in-out;

            &:hover {
                filter: brightness(2);
            }
        }
    }

    .logos {
        font-size: 12px;
        color: #4A4A4A;
        margin-bottom: 30px;

        > div {
            &:first-child {
                margin-right: 60px;

            }

            div {
                margin-bottom: 10px;

            }

            a {
                transition: filter 0.2s ease-in-out;

                &:hover {
                    filter: brightness(2);
                }
            }
        }
    }

    .copyright {
        font-size: 12px;
        color: #4A4A4A;
    }

    .pattern {
        position: absolute;
        top: 0;
        right: 30px;
        bottom: 0;
        width: 140px;
        height: 100%;
        background: url(../assets/images/popup-pattern.png) top left / 130%;

        @media (min-width: 768px) {
            width: 200px;
            right: 90px;
        }
        @media (min-width: 1280px) {
            right: 150px;
            width: 300px;
            background: url(../assets/images/pattern.png) top / 100%;
        }
        @media (min-width: 1440px) {
            right: 170px;
        }
        @media (min-width: 1920px) {
            right: 190px;
            width: 400px;
        }
    }
}

.button {
    background-color: #fff;
    color: #000;
    padding: 10px 15px;
    text-decoration: none;
    font-weight: 700;
    transition: background-color, color, 0.2s ease-in-out;
    border: none;

    @media (min-width: 1280px) {
        padding: 10px;
    }

    &:hover {
        @media (min-width: 1280px) {
            background-color: #858585;
            color: #000;
        }
    }

    &.active {
        background-color: #17171C;
        color: #fff;
    }

    &-outline {
        padding: 12px 25px;
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        text-decoration: none;

        &:hover {
            border: 2px solid #858585 !important;
            color: #858585 !important;
            background-color: transparent;
        }
    }

    &.negative {
        background-color: transparent;

        &:hover {
            a {
                color: #858585;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            transition: color 0.2s ease-in-out;
        }
    }
}

.color-button {
    width: 24px;
    height: 24px;
    border: 1px solid #313131;
    transition: transform 0.2s ease-in-out;

    @media (min-width: 1440px) {
        width: 28px;
        height: 28px;
    }

    &.active {
        border: 2px solid #fff;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:hover:not(.active) {
        transform: scale(0.8);
    }
}

.section {
    position: relative;
    color: #fff;

    h2 {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 200;
        color: #858585;
        letter-spacing: 2px;
        line-height: 1.4;

        @media (min-width: 768px) {
            font-size: 42px;
        }

        @media (min-width: 1280px) {
            font-size: 50px;
            letter-spacing: 5px;
        }

        @media (min-width: 1440px) {
            margin-bottom: 20px;
            font-size: 62px;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        max-width: 650px;
        @media (min-width: 1440px) {
            font-size: 20px;
        }
    }
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    align-items: center !important;
    left: auto !important;
    width: auto !important;
    right: 30px !important;
    bottom: 30px !important;

}

.swiper-pagination {
    .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        background-color: #fff;
        opacity: 0.2;
        border-radius: 0;
        margin-left: 0 !important;
        @media (min-width: 768px) {
            width: 18px;
            height: 18px;
        }

        &:not(:last-child) {
            margin-right: 15px !important;
            @media (min-width: 768px) {
                margin-right: 20px !important;
            }
        }

        &.swiper-pagination-bullet-active {
            width: 20px;
            height: 20px;
            opacity: 0.6;
            @media (min-width: 768px) {
                width: 30px;
                height: 30px;
            }
        }
    }
}
